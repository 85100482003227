<template>
  <v-row>
    <v-col cols="12" md="6">
       <v-text-field label="Mês/Ano"
                    dense
                    type="month"
                    v-model="value.mesRef"
                    :value="this.mesRefAtual"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
    </v-col>
  </v-row>
</template>

<script>

import moment from 'moment';

export default {
  name: "RelatorioAuditoriaForm",
  components: {
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    mesRefAtual: function () {
      if (typeof this.value.mesRef === "undefined"){
        this.value.mesRef = moment().format('YYYY-MM');
      }
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>